
















































import Vue from "vue";
import { authService } from "@/services/auth.service";
import { Messages } from "@/models/enums/messages.enum";
import { emailValidator } from "@/validator/globalvalidator";
export default Vue.extend({
  name: "ForgotPassword",
  data() {
    return {
      form: this.$form.createForm(this, { name: "signinForm" }),
      formRules: {
        email: {
          label: "lbl_email",
          name: "email",
          placeholder: "lbl_email_placeholder",
          decorator: [
            "email",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: emailValidator,
                },
              ],
            },
          ],
        },
      },
      isFormSubmitted: false as boolean,
    };
  },
  methods: {
    submitForm(e: Event): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err || this.isFormSubmitted) return;
        this.isFormSubmitted = true;
        authService
          .forgotPassword(values)
          .then(() => {
            this.$notification.success({
              description: Messages.FORGOT_PASSWORD_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.$router.push("/auth/signin");
            // Set User Info;
            this.isFormSubmitted = false;
          })
          .catch(() => (this.isFormSubmitted = false));
      });
    },
  },
});
